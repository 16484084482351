<script lang="ts" setup>
</script>

<template>
  <span class="asterisk">*</span>
</template>

<style lang="scss" scoped>
@import 'assets/css/variables';

.asterisk {
  color: $main-red-color;
  margin-left: 4px;
}
</style>
